@import "../../../sass/variables";

.main-content {
  padding-bottom: 40px;

  .edit-buttons {
    position: fixed;
    margin: 0;
    bottom: 30px;
    right: 20px;

    .floating-fab {
      position: relative;
      margin: 0 10px;
    }
  }
}

.button-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  > .MuiTypography-root {
    margin: 22px 0;

    a {
      color: $sols-primary;
    }
  }

  > .MuiButton-root {
    margin: 1% .2%;
  }

  > .allow-view-checkbox {
    margin: 22px 0;

    .MuiFormControlLabel-root {
      margin: 0;
    }

    .MuiCheckbox-root {
      color: $sols-primary;
    }

    .MuiTypography-body1 {
      font-weight: 600;
      color: $sols-text-primary;
    }
  }
}
