.MuiContainer-root.student-page {
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  padding: 0;

  .student-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: black;

    &.clickable {
      cursor: pointer;
    }

    .intro-video-player {
      background: black;
      z-index: 1;
    }

    .centered-button {
      width: 20%;
      height: 75px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      h1 {
        margin: 0 auto;
      }
    }

    .student-slide-holder {
      --ratio-height: 9;
      --ratio-width: 16;
      --slide-height: calc(100vw * var(--ratio-height) / var(--ratio-width));
      height: var(--slide-height);
      max-height: 100vh;
      max-width: calc(100vh * var(--ratio-width) / var(--ratio-height));
      position: relative;

      iframe {
        width: 100%;
      }

      .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .enable-play-curtain {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
      }
    }
  }
}