
.block-preview {
  width: 100%;
  height: auto;
  margin: 10px 0;
  display: grid;
  grid: "block-name lessons" / 230px 1fr;

  &.editing {
    grid: "drag-handle block-name lessons" / 40px 230px 1fr;
  }

  .drag-handle {
    grid-area: drag-handle;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .preview-name {
    grid-area: block-name;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .MuiTypography-root {
      text-transform: uppercase;
      font-weight: 600;
    }

    .strands-sets-dropdown {
      margin-top: 10px;
    }
  }

  .preview-lessons {
    grid-area: lessons;
    padding: 12px 0 20px 14px;
    display: flex;
    flex-flow: row wrap;

    .lesson-preview {
      width: 160px;
      height: 90px;
      margin: 8px;
      position: relative;

      img {
        display: block;
        max-width: 160px;
        max-height: 90px;
        width: auto;
        height: auto;
        position: absolute;
      }

      .lesson-active-indicator {
        width: 30px;
        height: 30px;
        top: 4px;
        right: 6px;
        position: absolute;

        .MuiSvgIcon-root {
          width: 1.25em;
          height: 1.25em;
          fill: green;
        }
      }

      &.lesson-inactive {
        img {
          filter: grayscale(1);
        }

        .lesson-active-indicator .MuiSvgIcon-root {
          fill: red;
        }
      }
    }
  }
}
