@import "../../sass/variables";

.lesson-stepper-container {
  &.MuiPaper-root {
    background-color: unset;

    .MuiStep-root {
      &:not(.activity) {
        &+.activity {
          .MuiStepConnector-alternativeLabel {
            left: calc(-125% + 31px);
          }
        }
      }

      &.activity {
        flex: .4;

        &+:not(.activity) {
          .MuiStepConnector-alternativeLabel {
            left: calc(-19% + 8px);
          }
        }

        .MuiStepConnector-alternativeLabel {
          left: calc(-50% + 15px);
          right: calc(50% + 15px);
        }

        .MuiStepButton-root {
          .MuiStepLabel-root {
            padding-top: 2px;
          }
        }
      }

      .MuiStepButton-root {
        width: 100%;
        margin: -24px -16px;
        padding: 24px 16px;
        box-sizing: content-box;

        .MuiStepLabel-labelContainer {
          .MuiTypography-root {
            color: $sols-gray;
            font-size: .85rem;

            &.MuiStepLabel-active {
              color: $sols-text-primary;
              //font-size: 1.15rem;
            }
          }
        }
      }
    }
  }
}