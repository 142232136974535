
// Custom SOLS Colors

$sols-background: #000;

$sols-gray: rgba(0, 0, 0, 0.38);

$sols-primary: #9FCB3B;
$sols-secondary: #346FA5;

$sols-text-primary: #346FA5;
$sols-text-secondary: #FFF;
