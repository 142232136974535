@import "../../../sass/variables";

.reel-item-card {
  border-color: $sols-secondary;
  border-style: solid;
  border-width: 3px;

  .MuiCardMedia-root {
    height: 300px;
    background-size: 100% 100%;

    .reel-item-checkmark {
      width: 45px;
      height: 45px;
      position: absolute;
      right: 10px;
      top: 10px;

      background: url("../../../images/checkmark.svg") no-repeat center;
      background-size: cover;
    }
  }

  .MuiCardContent-root {
    background: $sols-secondary;

    * {
      text-align: center;
    }
  }
}

.reel-item-card.active {
  border-color: $sols-primary;

  .MuiCardContent-root {
    background: $sols-primary;
  }
}
