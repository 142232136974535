
.student-session-modal {
  position: absolute;
  width: 45%;
  min-width: 600px;
  background-color: white;
  padding: 10px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .MuiPaper-root {
    padding: 10px;

    .modal-header {
      display: flex;
      justify-content: space-between;

      .MuiIconButton-root {
        padding: 0;
      }
    }
  }

  &:focus {
    outline: none;
  }

  form {
    display:flex;
    flex-direction: column;

    > * {
      margin: 10px 0;
    }

    button {
      margin: 0 30%;
    }
  }
}
