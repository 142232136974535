@import "../../sass/variables";

.block-tabs-container {
  .MuiTabs-scrollButtons svg {
    fill: $sols-secondary;
  }

  .MuiTab-textColorPrimary {
    color: $sols-text-primary;
  }
}