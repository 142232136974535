
.select-theme-page .main-content {
  .theme-select-section {
    display: grid;
    grid: max-content / repeat(4, 1fr);
    gap: 20px;
  }

  .floating-fab {
    position: fixed;
    bottom: 30px;
    left: 85%;
  }
}
