
.select-set-page .main-content {
  .set-select-section {
    width: 100%;
    grid-area: selector;
  }

  .floating-fab {
    position: fixed;
    bottom: 30px;
    left: 85%;
  }
}
