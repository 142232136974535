@import "../../../sass/variables";

.reel-items-radio-group {
  padding: 20px 50px;

  .MuiAccordionSummary-root {
    min-height: 0;

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordionSummary-expandIcon {
      padding: 0;
    }

    .reel-item-radio-button {
      color: $sols-text-primary;
    }
  }

  .MuiCollapse-container .MuiAccordionDetails-root {
    padding-top: 0;

    .MuiTypography-root {
      margin-left: 60px;

      h4 {
        margin: 0;
      }
    }
  }
}