@import "../../sass/variables";

.header {
  width: 100%;
  padding-top: 10px;
  display: grid;
  grid: "logo title user-tools"
   / 200px 1fr 200px;
  align-items: center;

  .header-item > * {
    color: $sols-text-primary;
  }

  .logo {
    grid-area: logo;
    text-align: left;
  }

  .title {
    grid-area: title;
    text-align: center;
  }

  .user-tools {
    * {
      display: none;
    }

    grid-area: user-tools;
    text-align: right;
  }
}
