@import "../../../sass/variables";

.step-navigator {
  grid-area: step-navigator;
  margin: 30px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  column-gap: 20px;

  .step-navigation-item {
    display: flex;
    flex-flow: column nowrap;
    width: 85%;
    height: 70px;
    text-align: center;
    margin: 0 auto;
    background: url('../../../images/step-frame.svg') no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    align-items: center;
    padding-right: 30px;

    * {
      color: $sols-text-primary;
      margin: 0;
    }

    h3 {
      font-size: 1.15rem;
    }

    h6 {
      font-size: 0.95rem;
    }
  }

  .step-navigation-item.active {
    background: url('../../../images/step-frame-active.svg') no-repeat;
    background-size: 100% 100%;

    * {
      color: $sols-text-secondary;
    }
  }
}
