@import "variables";

/* SOLS - General styles */

* {
  box-sizing: border-box;
}

html {
  background-color: #F0F4F7;
}

body {
  margin: 0;
  padding: 0;
}
